














































































import { format, getTime } from '@/filter/dateFormat';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, INIT_CREATE, SET_LIST_PAGE } from '../store';
import { PaginatableMixin } from '@/views/mixin';
import TermTable from '../components/TermTable.vue';
import TermCards from '../components/TermCards.vue';
import { Term, TermList, TermView } from '../types';
import { ApiResponse, Pagination } from '@/components/types';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import RemoveTermDialog from '../components/RemoveTermDialog.vue';
import CreateTermDialog from '../components/CreateTermDialog.vue';
import EditTermDialog from '../components/EditTermDialog.vue';
import InProgressTermDialog from '../components/InProgressTermDialog.vue';
import FinishTermDialog from '../components/FinishTermDialog.vue';
import ListExportDialog from '../components/ListExportDialog.vue';
import CardHeader from '@/components/CardHeader.vue';
import ResetTermDialog from '@/modules/term/components/ResetTermDialog.vue';

const Term = namespace('term');

export type TermOrdering = 'sortedDeadline' | 'sortedBegin';

@Component({
  components: {
    ResetTermDialog,
    CardHeader,
    ListExportDialog,
    FinishTermDialog,
    InProgressTermDialog,
    EditTermDialog,
    CreateTermDialog,
    RemoveTermDialog,
    ListSearchDialog,
    TermCards,
    TermTable
  }
})
export default class List extends PaginatableMixin {
  @Term.State('list') list!: TermList;
  @Term.Action(FETCH_LIST) fetch!: (employee?: string) => Promise<ApiResponse<Term[]>>;
  @Term.Mutation(SET_LIST_PAGE) setPage!: (page: number) => any;
  @Term.Mutation(INIT_CREATE) initCreate!: (data: object) => void;

  loading: boolean = false;
  success: boolean = false;
  showFutureTerms: boolean = false;

  termToFinish: TermView | null = null;
  termToProgress: TermView | null = null;
  termToRemove: TermView | null = null;
  termToEdit: TermView | null = null;
  termToReset: TermView | null = null;

  pagination: Pagination = {
    sortBy: [this.orderingProp],
    itemsPerPage: 10,
    page: 1,
    descending: false
  };

  get orderingProp(): TermOrdering {
    return this.$auth.user().configurations.term_ordering === 'deadline' ? 'sortedDeadline' : 'sortedBegin';
  }

  get items(): TermView[] {
    let list = this.list.items || [];

    if (!this.showFutureTerms) {
      list = list.filter((term) => term.begin <= format(Date.now(), 'yyyy-MM-dd'));
    }

    return list
      .map(({ conservatee, ...term }) => ({
        ...term,
        conservatorshipId: conservatee.id,
        conservatee: `${conservatee.surname}, ${conservatee.forename}`,
        beginTrans: format(term.begin, 'dd.MM.yyyy'),
        deadlineTrans: format(term.deadline, 'dd.MM.yyyy'),
        sortedDeadline: `${term.deadline}-${conservatee.surname}-${term.title}-${term.created}`,
        sortedBegin: `${term.begin}-${conservatee.surname}-${term.title}-${term.created}`
      }));
  }

  itemClasses(term: TermView): string {
    if (term.state === 'open' && getTime(term[this.$auth.user().configurations.term_ordering]) < Date.now()) {
      return 'error';
    }

    if (term.state === 'in_progress') {
      return 'warning';
    }

    if (term.state === 'done') {
      return 'success';
    }

    return '';
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}

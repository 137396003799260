






























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import { TermView } from '@/modules/term/types';
import AppTable from '@/components/AppTable.vue';
import { Pagination } from '@/components/types';

@Component({
  components: { AppTable }
})
export default class List extends AuthorizationMixin {
  @Prop({ default: () => [] }) itemClasses!: (data?: any) => string[];
  @Prop({ type: Object }) pagination!: Pagination;
  @Prop({ default: () => [] }) items!: TermView[];

  options: Pagination = {};

  @Watch('pagination', { immediate: true, deep: true })
  onPaginationChange(pagination: Pagination) {
    if (JSON.stringify(pagination) === JSON.stringify(this.options)) return;

    this.options = { ...pagination };
  }

  @Watch('options', { deep: true })
  onOptionsChange(options: Pagination) {
    this.$emit('update:pagination', options);
  }

  get headers(): any[] {
    return [
      { align: 'left', text: this.$i18n.t('common.finish'), value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('common.begin'), value: 'sortedBegin' },
      { align: 'left', text: this.$i18n.t('common.deadline'), value: 'sortedDeadline' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatee' },
      ...(this.isAdmin() && this.$vuetify.breakpoint.mdAndUp ? [
        { align: 'left', text: this.$i18n.t('common.name'), value: 'employee.name' }
      ] : []),
      { align: 'left', text: this.$i18n.t('common.title'), value: 'title' },
      ...(this.$vuetify.breakpoint.mdAndUp ? [
        { align: 'left', text: this.$i18n.t('common.actions'), value: 'deadlineTrans', sortable: false }
      ] : [])
    ];
  }
}

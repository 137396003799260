var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',_vm._b({attrs:{"headers":_vm.headers,"hide-default-footer":"","no-data-text":_vm.$t('term.message.notLoaded'),"pagination":_vm.options,"items":_vm.items},on:{"update:pagination":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.itemClasses(item)},[_c('td',[(item.state !== 'done')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('finish', item)}}},on),[_c('v-icon',[_vm._v("check")])],1)]}}],null,true)},[_c('span',[_vm._v("Frist abschließen")])]):_vm._e(),(item.state !== 'done')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","disabled":item.state === 'in_progress'},on:{"click":function($event){return _vm.$emit('progress', item)}}},[_c('v-icon',[_vm._v("loop")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Frist in Bearbeitung setzen")])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.beginTrans))]),_c('td',[_vm._v(_vm._s(item.deadlineTrans))]),_c('td',[_vm._v(_vm._s(item.conservatee))]),(_vm.isAdmin() && _vm.$vuetify.breakpoint.mdAndUp)?_c('td',[_vm._v(" "+_vm._s(item.employee.name)+" ")]):_vm._e(),_c('td',[(item.note)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"no-select"},on),[_vm._v(_vm._s(item.title))])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.note.replace(/(?:\r\n|\r|\n)/g, '<br>'))}})]):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","disabled":item.state === 'done'},on:{"click":function($event){return _vm.$emit('edit', item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(" Frist bearbeiten ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","disabled":item.state !== 'in_progress'},on:{"click":function($event){return _vm.$emit('reset', item)}}},on),[_c('v-icon',[_vm._v("undo")])],1)]}}],null,true)},[_c('span',[_vm._v(" Frist auf \"offen\" zurücksetzen ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('remove', item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Frist löschen ")])])],1):_vm._e()])]}}])},'app-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }
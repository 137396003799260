























































































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import CardRow from '@/components/CardRow.vue';
import AppIterator from '@/components/AppIterator.vue';
import { TermView } from '../types';

@Component({
  components: { CardRow, AppIterator }
})
export default class TermCards extends AuthorizationMixin {
  @Prop({ default: () => [] }) itemClasses!: (data?: any) => string[];
  @Prop({ default: () => [] }) items!: TermView[];

}
